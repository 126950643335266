<template>
    <div class="row">
        <div v-for="row in rows" :key="row.id" class="col-2 p-4 text-center"
            @click="openModalView(row.ext, row.nome, row.arquivo)">

            <div class="card card-hover cursor-pointer">
                <div class="card-head p-2">
                    <h6>{{ this.truncateString(row.nome) }}</h6>
                </div>
                <div class="card-body">
                    <i class="bi bi-file-earmark-richtext-fill icon"></i>
                </div>

            </div>



        </div>
        <div class="col-2 p-4 text-center" @click="addAnexos()">

            <div class="card p-2 card-hover cursor-pointer">

                <div class="card-body p-4">
                    <i class="bi bi-plus-lg icon"></i>
                </div>

            </div>



        </div>
    </div>


    <modalWidgetVue v-if="modalCreate">
        <div class="row">
            <div class="col-12 pb-4">
                <i @click="modalCloseCreate()" class="bi bi-x float-end icon cursor-pointer"></i>
            </div>
            <div class="col-12">
                <CreateUserUnidadeAnexos></CreateUserUnidadeAnexos>
            </div>
        </div>
    </modalWidgetVue>
    <modalWidgetVue v-if="modalView">
        <div class="row">
            <div class="col-12 pb-4">
                <i @click="modalCloseView()" class="bi bi-x float-end icon cursor-pointer"></i>
            </div>
            <div class="col-12 text-center">
                <img v-if="isImage" style="max-height: 400px;" :src="imageSRC" alt="" srcset="">
                <i v-if="!isImage" class="bi bi-file-earmark-richtext-fill text-success img-icon"></i>
            </div>

            <div class="col-12">
                <button class="btn btn-primary" @click="download">
                    <i class="bi bi-cloud-arrow-down-fill"></i>
                </button>
            </div>
        </div>
    </modalWidgetVue>


</template>
<script>

import toastr from "toastr/build/toastr.min";
import user_unidade_anexoService from "@/services/user_unidade_anexo.service";
import modalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import CreateUserUnidadeAnexos from '@/views/user_unidade_anexos/CreateUserUnidadeAnexos.vue'
import StrUtil from "@/utils/str.util";

export default {
    name: "IndexUserUnidadeAnexos",
    components: { modalWidgetVue, CreateUserUnidadeAnexos },
    data() {
        return {
            rows: null,
            search: null,
            modalCreate: false,
            modalView: false,
            isImage: false,
            imageSRC: '',
            file: '',
            name: '',
            ext: '',
        }
    },
    methods: {
        async list() {
            let user_unidade_id = this.$route.params.id;
            let user_unidade_anexosService = new user_unidade_anexoService();
            let dataRow = await user_unidade_anexosService.list(this.search,user_unidade_id);


            if (dataRow.data.data.length > 0) {

                this.rows = dataRow.data.data;

            }


        },
        async deleteRow(id) {
            let user_unidade_anexosService = new user_unidade_anexoService();
            let dataRow = await user_unidade_anexosService.delete(id);
            if (dataRow.data.success) {
                this.list();
                toastr.success('Apagado com sucesso');
            } else {
                toastr.error('Houve um problema ao apagar');
            }
        },
        openModalView(ext, nome, arquivo) {
            this.imageSRC = ''
            let supportedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg', 'ico', 'avif'];
            this.isImage = supportedExtensions.includes(ext)
            if (this.isImage) {
                this.imageSRC = arquivo;
            }


            this.file = arquivo;
            this.name = nome;
            this.ext = ext;

            this.modalView = true;
        },
        download() {
            const base64 = this.file.split(',')[1];
            console.log(base64);
            const fileName = this.name + '.' + this.ext;

            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            // Criar um URL para o blob
            const blobUrl = URL.createObjectURL(blob);

            // Criar um link de download e iniciar o download
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remover o link de download
            document.body.removeChild(downloadLink);

            // Liberar o URL do blob
            URL.revokeObjectURL(blobUrl);
        },
        addAnexos() {
            console.log('adicionar');
            this.modalCreate = true;
        },
        modalCloseCreate() {
            this.modalCreate = false;
        },
        modalCloseView() {
            this.modalView = false;
        },
        truncateString(text) {
            return new StrUtil().truncateString(text)
        }

    },
    created() {
        this.list();

    }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style>
.icon {
    font-size: 46px !important;
}
.img-icon {
    font-size: 300px !important;
}

.card-hover:hover {
    background-color: rgb(248, 245, 245);
}
</style>
