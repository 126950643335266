import {createRouter, createWebHistory} from "vue-router";
import DashBoardPage from "@/views/pages/DashBoardPage.vue";
import LoginForm from "@/views/auth/LoginForm.vue";
import notFound from "@/views/errors/NotFound.vue";
import Middleware from "@/services/Middleware";
import IndexUsers from "@/views/users/IndexUsers.vue";
import createUsers from "@/views/users/CreateUsers.vue";
import editUsers from "@/views/users/EditUsers.vue";
import EditLeads from "@/views/leads/EditLeads.vue";
import createLeads from "@/views/leads/CreateLeads.vue";
import IndexLeads from "@/views/leads/IndexLeads.vue";
import IndexClientes from "@/views/clientes/IndexClientes.vue";
import createClientes from "@/views/clientes/CreateClientes.vue";
import editClientes from "@/views/clientes/EditClientes.vue";
import IndexPessoaCategorias from "@/views/pessoa_categorias/IndexPessoaCategorias.vue";
import createPessoaCategorias from "@/views/pessoa_categorias/CreatePessoaCategorias.vue";
import editPessoaCategorias from "@/views/pessoa_categorias/EditPessoaCategorias.vue";
import indexFornecedores from "@/views/fornecedores/IndexFornecedores.vue";
import createFornecedores from "@/views/fornecedores/CreateFornecedores.vue";
import editFornecedores from "@/views/fornecedores/EditFornecedores.vue";
import indexFuncionarios from "@/views/funcionarios/IndexFuncionarios.vue";
import createFuncionarios from "@/views/funcionarios/CreateFuncionarios.vue";
import editFuncionarios from "@/views/funcionarios/EditFuncionarios.vue";
import indexGerentes from "@/views/gerentes/IndexGerentes.vue";
import createGerentes from "@/views/gerentes/CreateGerentes.vue";
import editGerentes from "@/views/gerentes/EditGerentes.vue";
import IndexProdutos from "@/views/produtos/IndexProdutos.vue";
import CreateProdutos from "@/views/produtos/CreateProdutos.vue";
import EditProdutos from "@/views/produtos/EditProdutos.vue";
import indexProdutoCategorias from "@/views/produto_categorias/IndexProdutoCategorias.vue";
import createProdutoCategorias from "@/views/produto_categorias/CreateProdutoCategorias.vue";
import EditProdutoCategorias from "@/views/produto_categorias/EditProdutoCategorias.vue";
import indexProdutoQuestionarios from "@/views/produto_questionarios/IndexProdutoQuestionarios.vue";
import createProdutoQuestionarios from "@/views/produto_questionarios/CreateProdutoQuestionarios.vue";
import editProdutoQuestionarios from "@/views/produto_questionarios/EditProdutoQuestionarios.vue";
import indexProdutoPerguntas from "@/views/produto_perguntas/IndexProdutoPerguntas.vue";
import createProdutoPerguntas from "@/views/produto_perguntas/CreateProdutoPerguntas.vue";
import editProdutoPerguntas from "@/views/produto_perguntas/EditProdutoPerguntas.vue";
import indexPedidoPropostas from "@/views/pedido_propostas/IndexPedidoPropostas.vue";
import editPedidoPropostas from "@/views/pedido_propostas/EditPedidoPropostas.vue";
import FormPage from "@/views/form/FormPage.vue";
import QuestionarioPedidoPropostas from "@/views/pedido_propostas/QuestionarioPedidoPropostas.vue";
import IndexUserUnidades from "@/views/user_unidades/IndexUserUnidades.vue";
import CreateUserUnidades from "@/views/user_unidades/CreateUserUnidades.vue";
import EditUserUnidades from "@/views/user_unidades/EditUserUnidades.vue";
const routes = [
    {
        path: '/login',
        name: 'LoginForm',
        component: LoginForm,
        meta: {
            auth: false
        }

    },
    //user

    {
        path: '/users/create',
        name: 'usersCreate',
        component: createUsers,
        meta: {
            auth: true
        }
    },

    {
        path: '/users/index',
        name: 'users',
        component: IndexUsers,
        meta: {
            auth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'editUsers',
        component: editUsers,
        meta: {
            auth: true
        }
    },

    //leads
    {
        path: '/leads/edit/:id',
        name: 'editLeads',
        component: EditLeads,
        meta: {
            auth: true
        }
    },
    {
        path: '/leads/create',
        name: 'leadsCreate',
        component: createLeads,
        meta: {
            auth: true
        }
    },

    {
        path: '/leads/index',
        name: 'leads',
        component: IndexLeads,
        meta: {
            auth: true
        }
    },

    //cliente

    {
        path: '/clientes/index',
        name: 'clientes',
        component: IndexClientes,
        meta: {
            auth: true
        }
    },
    {
        path: '/clientes/create',
        name: 'clienteCreate',
        component: createClientes,
        meta: {
            auth: true
        }
    },
    {
        path: '/clientes/edit/:id',
        name: 'editCliente',
        component: editClientes,
        meta: {
            auth: true
        }
    },
     //user_unidades

     {
        path: '/user_unidades/index',
        name: 'IndexUserUnidades',
        component: IndexUserUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/user_unidades/create',
        name: 'CreateUserUnidades',
        component: CreateUserUnidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/user_unidades/edit/:id',
        name: 'EditUserUnidades',
        component: EditUserUnidades,
        meta: {
            auth: true
        }
    },
    //pessoas categorias
    {
        path: '/pessoa_categorias/index',
        name: 'IndexPessoaCategorias',
        component: IndexPessoaCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/pessoa_categorias/create',
        name: 'createPessoaCategorias',
        component: createPessoaCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/pessoa_categorias/edit/:id',
        name: 'editPessoaCategorias',
        component: editPessoaCategorias,
        meta: {
            auth: true
        }
    },
    //fornecedores
    {
        path: '/fornecedores/index',
        name: 'IndexPessoaFornecedores',
        component: indexFornecedores,
        meta: {
            auth: true
        }
    },
    {
        path: '/fornecedores/create',
        name: 'createFornecedores',
        component: createFornecedores,
        meta: {
            auth: true
        }
    },
    {
        path: '/fornecedores/edit/:id',
        name: 'editFornecedores',
        component: editFornecedores,
        meta: {
            auth: true
        }
    },

    //funcionarios
    {
        path: '/funcionarios/index',
        name: 'indexFuncionarios',
        component: indexFuncionarios,
        meta: {
            auth: true
        }
    },
    {
        path: '/funcionarios/create',
        name: 'createFuncionarios',
        component: createFuncionarios,
        meta: {
            auth: true
        }
    },
    {
        path: '/funcionarios/edit/:id',
        name: 'editFuncionarios',
        component: editFuncionarios,
        meta: {
            auth: true
        }
    },
    //gerentes
    {
        path: '/gerentes/index',
        name: 'indexGerentes',
        component: indexGerentes,
        meta: {
            auth: true
        }
    },
    {
        path: '/gerentes/create',
        name: 'createGerentes',
        component: createGerentes,
        meta: {
            auth: true
        }
    },
    {
        path: '/gerentes/edit/:id',
        name: 'editGerentes',
        component: editGerentes,
        meta: {
            auth: true
        }
    },
   

    //produtos
    {
        path: '/produtos/index',
        name: 'indexProduto',
        component: IndexProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/produtos/create',
        name: 'CreateProdutos',
        component: CreateProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/produtos/edit/:id',
        name: 'EditProdutos',
        component: EditProdutos,
        meta: {
            auth: true
        }
    },
    //produto_categorias
    {
        path: '/produto_categorias/index',
        name: 'indexProdutoCategorias',
        component: indexProdutoCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_categorias/create',
        name: 'createProdutoCategorias',
        component: createProdutoCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_categorias/edit/:id',
        name: 'EditProdutoCategorias',
        component: EditProdutoCategorias,
        meta: {
            auth: true
        }
    },
    //produto_questionarios
    {
        path: '/produto_questionarios/index/:id',
        name: 'indexProdutoQuestionarios',
        component: indexProdutoQuestionarios,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_questionarios/create/:id',
        name: 'createProdutoQuestionarios',
        component: createProdutoQuestionarios,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_questionarios/edit/:id',
        name: 'editProdutoQuestionarios',
        component: editProdutoQuestionarios,
        meta: {
            auth: true
        }
    },

    //produto_perguntas
    {
        path: '/produto_perguntas/index/:id',
        name: 'indexProdutoPerguntas',
        component: indexProdutoPerguntas,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_perguntas/create/:id',
        name: 'createProdutoPerguntas',
        component: createProdutoPerguntas,
        meta: {
            auth: true
        }
    },
    {
        path: '/produto_perguntas/edit/:id',
        name: 'editProdutoPerguntas',
        component: editProdutoPerguntas,
        meta: {
            auth: true
        }
    },

    //pedido_propostas
    {
        path: '/pedido_propostas/index',
        name: 'indexPedidoPropostas',
        component: indexPedidoPropostas,
        meta: {
            auth: true
        }
    },

    {
        path: '/pedido_propostas/edit/:id',
        name: 'editPedidoPropostas',
        component: editPedidoPropostas,
        meta: {
            auth: true
        }
    },
    {
        path: '/pedido_propostas/questionario/:id',
        name: 'QuestionarioPedidoPropostas',
        component: QuestionarioPedidoPropostas,
        meta: {
            auth: true
        }
    },

    //form

    {
        path: '/form/:hash',
        name: 'FormPage',
        component: FormPage,
        meta: {
            auth: false
        }
    },

    //sistema
    {
        path: '/painel',
        name: 'painel',
        component: DashBoardPage,
        meta: {
            auth: true
        }
    },


    {
        path: '/404',
        component: notFound
    },
    {
        path: '/',
        redirect: '/login'
    }

];
const router = createRouter({history: createWebHistory(), routes});
router.beforeEach((to) => {
    let middleware = new Middleware();
    if (!middleware.logout(to)) {
        middleware.routeExists(to);
        middleware.validateHash(to);

    }


})
router.afterEach((to) => {
    let middleware = new Middleware();
    middleware.setRegisterLastRouteBeforeLogin();
    middleware.userPermissions(to);
    middleware.finishLoading();
});
export default router;