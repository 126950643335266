<template>
    <layout-page>
        <div class="card-header">
            <div class="row">
                <div class="col-12 ps-4 pt-3 ">
                    <div class="float-start">
                        <h5>Adicionar Unidades</h5>
                    </div>
                    <div class="float-end">
                        <button-widget cor="azul" href="./index" tamanho="M">
                            Voltar
                        </button-widget>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <FormUserUnidades></FormUserUnidades>
                    <div class="col-4">
                    <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
                    </div>
                </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormUserUnidades from "@/views/user_unidades/FormUserUnidades.vue";
import user_unidadeService from "@/services/user_unidade.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateUserUnidades",
    components: {FormUserUnidades, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        nome: document.getElementById('nome').value,
descritivo: document.getElementById('descritivo').value,
cidade_id: document.getElementById('cidade_id').value,
cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),


        }
            let user_unidadesService = new user_unidadeService();
            let response = await user_unidadesService.store(dataForm);

            if(response.data?.id){
                location.href = '/user_unidades/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>
