<script>
import produto_perguntaService from "@/services/produto_pergunta.service";
import Helpers from "@/services/Helpers";

export default {
  name: "listEscolha",
  data() {
    return {
      questionario_id: null,
      rows: [{'ordem':0,'message':''}],
      tipo: null,
    }
  },
  methods:{
    async findOptions(){
      let pergunta_id =this.$route.params.id;
      console.log(pergunta_id);

      let produto_perguntasService = new produto_perguntaService();
      let response = await produto_perguntasService.findOptions(pergunta_id);
      if(new Helpers().empty(response.data)){
        return ;
      }

      this.rows = response.data;

    },
    addRow() {
      let respostas = document.getElementsByClassName('resposta');
      for (const i in this.rows) {
        this.rows[i].message = respostas[i].value;
      }
      this.rows.push({ordem: this.rows.length, message: ''});
    },
    deleteRow(ordem) {
      let row = [];
      let j = 0;

      for (const i in this.rows) {

        if (i != ordem) {
          row.push({ordem: (i - j), message: this.rows[i].message})
        } else if (i == ordem) {
          j++;
        }
      }
      this.rows = row;
    }
  },
  mounted() {
   this.findOptions()
  }
}
</script>

<template>

    <div class="row options" v-bind:key="row" v-for="row in rows">
      <div class="col-2">
        <label class="p-2">Ordem</label>
        <input :value="row.ordem" step="0" type="number" class="ordem-option form-control decimal">
      </div>
      <div class="col-6">
        <label class="p-2">Resposta</label>
        <input :value="row.message" type="text" class="resposta form-control decimal">
      </div>
      <div class="col-4 mt-5">
        <button class="btn btn-danger me-2" @click="addRow()">+</button>
        <button class="btn btn-danger" @click="deleteRow(row.ordem)">x</button>
      </div>
    </div>

</template>

<style scoped>

</style>