<template>

    <div class="card-body">
        <div class="row">
            <FormUserUnidadeAnexos></FormUserUnidadeAnexos>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </div>
</template>
<script>

import FormUserUnidadeAnexos from "@/views/user_unidade_anexos/FormUserUnidadeAnexos.vue";
import user_unidade_anexoService from "@/services/user_unidade_anexo.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateUserUnidadeAnexos",
    components: { FormUserUnidadeAnexos },
    methods: {
        async sendForm() {
            let dataForm = {
                user_unidade_id: this.$route.params.id,
                arquivo: document.getElementById('arquivo').getAttribute('data-value'),
                nome: document.getElementById('arquivo').getAttribute('data-name'),
                ext: document.getElementById('arquivo').getAttribute('data-ext'),
            }
            let user_unidade_anexosService = new user_unidade_anexoService();
            let response = await user_unidade_anexosService.store(dataForm);

            if (response.data?.id) {
                location.reload();
            } else {
                if (response.response.data?.message) {
                    toastr.error(response.response.data?.message);
                } else {
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped></style>
