<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Funcionários</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="../index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormFuncionarios></FormFuncionarios>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>

import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";

import pessoaService from "@/services/pessoa.service";
import FormFuncionarios from "@/views/funcionarios/FormFuncionarios.vue";

export default {
  name: "EditClientes",
  components: {FormFuncionarios, LayoutPage, ButtonWidget},
  methods: {
    async edit(id) {
      let pessoasService = new pessoaService();
      let response = await pessoasService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('cpf').value = response.data.cpf;
      document.getElementById('cnpj').value = response.data.cnpj;
      document.getElementById('email').value = response.data.email;
      document.getElementById('telefone').value = response.data.telefone;
      document.getElementById('celular').value = response.data.celular;
      document.getElementById('ativo').value = response.data.ativo;
      document.getElementById('cidade_id').value = response.data.cidade_id;
      document.getElementById('pessoa_categoria_id').value = response.data.pessoa_categoria_id;

    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        cpf: String(document.getElementById('cpf').value).replace(/[^a-zA-Z0-9]/g, ''),
        cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),
        email: document.getElementById('email').value,
        telefone: String(document.getElementById('telefone').value).replace(/[^a-zA-Z0-9]/g, ''),
        celular: String(document.getElementById('celular').value).replace(/[^a-zA-Z0-9]/g, ''),
        tipo: 'funcionario',
        ativo: document.getElementById('ativo').value,
        cidade_id: document.getElementById('cidade_id').value,
        pessoa_categoria_id: document.getElementById('pessoa_categoria_id').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let pessoasService = new pessoaService();
      let response = await pessoasService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
