import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";

export default class PessoasService {
    async list(search,type) {
        console.log(type)
        let dataRequest = {
            type:type,
        };
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                type:type,
                search: search
            };
        }
        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pessoas', dataRequest);

    }

    async delete(id) {
        let requestHelper = new RequestHelper();
        return await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pessoas/' + id);

    }

    async update(dataForm,id) {
        let request =  new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pessoas/'+id,dataForm);
    }

    async store(dataForm) {
        if (!dataForm.parent_id) {
            delete dataForm.parent_id
        }
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pessoas', dataForm);
    }

    async view(id) {
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pessoas/' + id, {});
    }

}
