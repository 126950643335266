<template>
    <input-form class-list="col-12"  type="string" label="Nome " value="" name="nome"/>
<input-form class-list="col-12"  type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form placeholder="Selecione Produto Categoria  " class-list="col-12" type="select2" url="/api/produto_categorias/list" label="Produto Categoria  " value="" name="produto_categoria_id"/>
<input-form class-list="col-12"  type="tinyint" label="Ativo " value="" name="ativo"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormProdutos",
    components: {InputForm},
    data() {
        return {}
    },
    methods:{},

created() {}
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
