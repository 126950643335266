<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Clientes</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormClientes></FormClientes>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import pessoaService from "@/services/pessoa.service";
import toastr from "toastr/build/toastr.min";
import FormClientes from "@/views/clientes/FormClientes.vue";

export default {
  name: "CreateClientes",
  components: {FormClientes, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        cpf: String(document.getElementById('cpf').value).replace(/[^a-zA-Z0-9]/g, ''),
        cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),
        email: document.getElementById('email').value,
        telefone: String(document.getElementById('telefone').value).replace(/[^a-zA-Z0-9]/g, ''),
        celular: String(document.getElementById('celular').value).replace(/[^a-zA-Z0-9]/g, ''),
        tipo:'cliente',
        ativo: document.getElementById('ativo').value,
        cidade_id: document.getElementById('cidade_id').value,
        pessoa_categoria_id: document.getElementById('pessoa_categoria_id').value,


      }
      let pessoasService = new pessoaService();
      let response = await pessoasService.store(dataForm);

      if (response.data?.id) {
        location.href = '/clientes/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
