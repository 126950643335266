<template>
  <nav-bar></nav-bar>

    <main class="content">

      <menu-row></menu-row>
      <div class="row">
        <div class="col-12 col-sm-4 col-xl-4 mb-4">
          <div class="card border-0 shadow">
            <div class="card-body">
              <div class="row d-block d-xl-flex align-items-center">
                <div
                    class="col-12 col-xl-4 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                  <div class="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                    <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div class="d-sm-none">
                    <h2 class="h5">Contatos</h2>
                    <h3 class="fw-extrabold mb-1">{{contatos}}</h3>
                  </div>
                </div>
                <div class="col-12 col-xl-7 px-xl-0">
                  <div class="d-none d-sm-block">
                    <h2 class="h6 text-gray-400 mb-0">Contatos</h2>
                    <h3 class="fw-extrabold mb-2">{{contatos}}</h3>
                  </div>
                  <small class="d-flex align-items-center text-gray-500">
                   {{labelContatos}}

                  </small>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-xl-4 mb-4">
          <div class="card border-0 shadow">
            <div class="card-body">
              <div class="row d-block d-xl-flex align-items-center">
                <div
                    class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                  <div class="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                    <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                            clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="d-sm-none">
                    <h2 class="fw-extrabold h5">Acessos</h2>
                    <h3 class="mb-1">{{acessos}}</h3>
                  </div>
                </div>
                <div class="col-12 col-xl-7 px-xl-0">
                  <div class="d-none d-sm-block">
                    <h2 class="h6 text-gray-400 mb-0">Acessos</h2>
                    <h3 class="fw-extrabold mb-2">{{acessos}}</h3>
                  </div>
                  <small class="d-flex align-items-center text-gray-500">
                   {{labelAcessos}}

                  </small>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-xl-4 mb-4">
          <div class="card border-0 shadow">
            <div class="card-body">
              <div class="row d-block d-xl-flex align-items-center">
                <div
                    class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                  <div class="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                    <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="d-sm-none">
                    <h2 class="fw-extrabold h5"> Não Resolvidos</h2>
                    <h3 class="mb-1">{{ naoResolvidos }}</h3>
                  </div>
                </div>
                <div class="col-12 col-xl-7 px-xl-0">
                  <div class="d-none d-sm-block">
                    <h2 class="h6 text-gray-400 mb-0"> Não Resolvidos</h2>
                    <h3 class="fw-extrabold mb-2">{{ naoResolvidos }}</h3>
                  </div>
                  <small class="text-gray-500">
                    {{labelNaoResolvidos}}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-12">
          <div class="row">
            <div class="col-12 mb-4">
              <div class="card border-0 shadow">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <h2 class="fs-5 fw-bold mb-0">Visita diária a paginas</h2>
                    </div>

                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr >
                      <th class="border-bottom" scope="col">local</th>
                      <th class="border-bottom" scope="col">Tempo médio na Pagina</th>
                      <th class="border-bottom" scope="col">Data</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="row in visitas" :key="row" >
                      <th class="text-gray-900" scope="row">
                        {{row.path_name}}
                      </th>
                      <td class="fw-bolder text-gray-500">
                        {{row.tempo}} segundos
                      </td>
                      <td class="fw-bolder text-gray-500">
                        {{row.inicio}}
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
</template>
<script>


import PainelService from "@/services/painel.service";

export default {
  name: "DashBoardPage",

  components: {},
  data() {
    return {
      contatos: '0,00',
      labelContatos: '',
      acessos: '0',
      naoResolvidos: '0',
      labelAcessos:'',
      labelNaoResolvidos:'',
      visitas:null
    }
  },
  methods: {
    async loadTotalizadores() {
      let painelService = new PainelService(process.env.VUE_APP_API_HOST_NAME);
      this.contatos  = await painelService.contatos();

      this.acessos = await  painelService.acessos();

      this.naoResolvidos  = await painelService.naoResolvido();
    },
    nomeEUltimoDiaDoMes() {
      // Obtém a data atual
      var dataAtual = new Date();

      // Obtém o número do mês (0 - janeiro, 1 - fevereiro, etc.)
      var numeroMes = dataAtual.getMonth();

      // Array com o nome dos meses
      var nomesMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

      // Obtém o nome do mês atual
      var nomeMesAtual = nomesMeses[numeroMes];

      // Obtém o último dia do mês atual
      var ultimoDia = new Date(dataAtual.getFullYear(), numeroMes + 1, 0).getDate();

      // Retorna um objeto com o nome do mês atual e o último dia do mês
      this.labelContatos = '1 até o dia '+ultimoDia+' de '+nomeMesAtual;
      this.labelAcessos = '1 até o dia '+ultimoDia+' de '+nomeMesAtual;
      this.labelNaoResolvidos = '1 até o dia '+ultimoDia+' de '+nomeMesAtual;
    },
    async listVisitas(){
      let painelService = new PainelService(process.env.VUE_APP_API_HOST_NAME);
      this.visitas  = await painelService.list();
    }
  },
  created() {
  },
  mounted() {
    this.loadTotalizadores()
    this.nomeEUltimoDiaDoMes()
    this.listVisitas()
  }

}


</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>

<script setup>

import NavBar from "@/components/page/navBar.vue";
import MenuRow from "@/components/page/menuRow.vue";

</script>