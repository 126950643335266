<template>
  <input-form class-list="col-12"  type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-12"  type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12"  type="mask" mask="000.000.000-00" label="CPF " value="" name="cpf"/>
  <input-form class-list="col-12"  type="mask" mask="00.000.000/0000-00" label="CNPJ " value="" name="cnpj"/>
  <input-form class-list="col-12"  type="string" label="E-mail " value="" name="email"/>
  <input-form class-list="col-12"  type="mask" mask="(00) 0 0000-0000" label="Telefone " value="" name="telefone"/>
  <input-form class-list="col-12"  type="mask" mask="(00) 0 0000-0000" label="Celular " value="" name="celular"/>
  <input-form class-list="col-12"  type="tinyint" label="Ativo " value="" name="ativo"/>
  <input-form placeholder="Selecione Cidade " class-list="col-12" type="select2" url="/api/cidades/list" label="Cidade " value="" name="cidade_id"/>
  <input-form placeholder="Selecione Categoria  " class-list="col-12" type="select2" url="/api/cliente_categorias/list" label="Categoria  " value="" name="pessoa_categoria_id"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormClientes",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
