<template>
    <layout-page>
        <div class="card-header">
            <div class="row">
                <div class="col-12 ps-4 pt-3 ">
                    <div class="float-start">
                        <h5>Adicionar Categorias</h5>
                    </div>
                    <div class="float-end">
                        <button-widget cor="azul" href="./index" tamanho="M">
                            Voltar
                        </button-widget>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <FormPessoaCategorias></FormPessoaCategorias>
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormPessoaCategorias from "@/views/pessoa_categorias/FormPessoaCategorias.vue";
import pessoa_categoriaService from "@/services/pessoa_categoria.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreatePessoaCategorias",
    components: {FormPessoaCategorias, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        nome: document.getElementById('nome').value,
descritivo: document.getElementById('descritivo').value,
tipo: document.getElementById('tipo').value,
ativo: document.getElementById('ativo').value,


        }
            let pessoa_categoriasService = new pessoa_categoriaService();
            let response = await pessoa_categoriasService.store(dataForm);

            if(response.data?.id){
                location.href = '/pessoa_categorias/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>
