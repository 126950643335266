<template>
  <input-form placeholder="Selecione Pessoa " class-list="col-12 disabled" type="select2" url="/api/pessoas/list"
              label="Pessoa " value="" name="pessoa_id"/>
  <input-form placeholder="Selecione Produto " class-list="col-12" type="select2" url="/api/produtos/list"
              label="Produto " value="" name="produto_id"/>


  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormPedidoPropostas",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  mounted() {
    document.getElementById('produto_id').setAttribute('disabled',true)
    document.getElementById('search-produto_id').setAttribute('disabled',true)
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
