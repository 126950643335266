<script>
import InputForm from "@/components/form/inputForm.vue";

export default {
  name: "ArquivoTipo",
  components: {InputForm}
}
</script>

<template>
  <input-form  class-list="col-12" type="int" label="Tamanho Maximo do arquivo MB " value=""
              name="tamanho"/>
</template>

<style scoped>

</style>