<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Questões</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'../index/'+questionario_id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormProdutoPerguntas></FormProdutoPerguntas>
        <list-escolha v-if="tipo==='multipla-escolha' || tipo==='unica-escolha'"></list-escolha>
        <texto-tipo  v-if="tipo==='texto'"></texto-tipo>
        <arquivo-tipo v-if="tipo==='arquivo'"></arquivo-tipo>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormProdutoPerguntas from "@/views/produto_perguntas/FormProdutoPerguntas.vue";
import produto_perguntaService from "@/services/produto_pergunta.service";
import toastr from "toastr/build/toastr.min";
import TextoTipo from "@/views/produto_perguntas/TextoTipo.vue";
import ListEscolha from "@/views/produto_perguntas/ListEscolha.vue";
import ArquivoTipo from "@/views/produto_perguntas/ArquivoTipo.vue";

export default {
  name: "CreateProdutoPerguntas",
  components: {ArquivoTipo, ListEscolha, TextoTipo, FormProdutoPerguntas, LayoutPage, ButtonWidget},
  data() {
    return {
      questionario_id: null,
      tipo: null
    }
  },
  methods: {
    async sendForm() {
      let dataForm = {
        enunciado: document.getElementById('enunciado').value,
        tipo: document.getElementById('tipo').value,
        obrigatorio: document.getElementById('obrigatorio').value,
        ordem: document.getElementById('ordem').value,
        questionario_id: this.$route.params.id,
        ativo: document.getElementById('ativo').value,
      }

      if (dataForm.tipo === 'multipla-escolha' || dataForm.tipo === 'unica-escolha') {
        let ordem = document.getElementsByClassName('ordem-option');
        let resposta = document.getElementsByClassName('resposta');
        let options = [];
        let validateIndice = 0;
        let validateOrdem = 0;
        for (let i = 0; i < resposta.length; i++) {
          if (!ordem[i].value || !resposta[i].value) {
            toastr.error('O item '+ i + ' está em branco');
            return false;
          }
          validateIndice += i;
          validateOrdem += Math.abs(Number(ordem[i].value));

          options.push(
              {ordem:  Math.abs(Number(ordem[i].value)), message: resposta[i].value}
          );

        }


        if (validateIndice !== validateOrdem) {
          toastr.error('A ordem das respostas está enumerada incorretamente');
          return false;
        }
        dataForm.options = options;

      } else if (dataForm.tipo === 'texto' || dataForm.tipo === 'arquivo') {
        dataForm.tamanho = document.getElementById('tamanho').value
      }

      let produto_perguntasService = new produto_perguntaService();
      let response = await produto_perguntasService.store(dataForm);

      if (response.data?.id) {
        location.href = '/produto_perguntas/index/'+this.questionario_id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    tipoQuestionChange(){
      document.getElementById('tipo').addEventListener('change',(element)=>{
        this.tipo = element.target.value;
      })
    }
  },
  created() {
    this.questionario_id = this.$route.params.id;


  },
  mounted() {
    this.tipoQuestionChange();
  }
}
</script>
<style scoped>
</style>
