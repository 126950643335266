<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Questões</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'../index/'+questionario_id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormProdutoPerguntas></FormProdutoPerguntas>
        <list-escolha v-if="tipo==='multipla-escolha' || tipo==='unica-escolha'"></list-escolha>
        <texto-tipo a v-if="tipo==='texto'"></texto-tipo>
        <arquivo-tipo v-if="tipo==='arquivo'"></arquivo-tipo>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormProdutoPerguntas from "@/views/produto_perguntas/FormProdutoPerguntas.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import produto_perguntaService from "@/services/produto_pergunta.service";
import ListEscolha from "@/views/produto_perguntas/ListEscolha.vue";
import TextoTipo from "@/views/produto_perguntas/TextoTipo.vue";
import ArquivoTipo from "@/views/produto_perguntas/ArquivoTipo.vue";

export default {
  name: "EditProdutoPerguntas",
  components: {ArquivoTipo, TextoTipo, ListEscolha, LayoutPage, ButtonWidget, FormProdutoPerguntas},
  data() {
    return {
      questionario_id: null,
      rows: null,
      tipo: null,
    }
  },

  methods: {
    async edit(id) {
      let produto_perguntasService = new produto_perguntaService();
      let response = await produto_perguntasService.view(id);
      this.tipo = await response.data.tipo;
      document.getElementById('enunciado').value = await response.data.enunciado;
      document.getElementById('tipo').value = await response.data.tipo;
      document.getElementById('obrigatorio').value = await response.data.obrigatorio;
      document.getElementById('ordem').value = await response.data.ordem;
      document.getElementById('ativo').value = await response.data.ativo;
      this.questionario_id = await response.data.questionario_id;
      if(this.tipo==='texto' || this.tipo==='arquivo'){
        document.getElementById('tamanho').value = await response.data.tamanho;
      }


    },
    async sendForm() {
      let dataForm = {
        enunciado: document.getElementById('enunciado').value,
        tipo: document.getElementById('tipo').value,
        obrigatorio: document.getElementById('obrigatorio').value,
        ordem: document.getElementById('ordem').value,
        ativo: document.getElementById('ativo').value,
        _method: 'PUT'

      }

      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      if (dataForm.tipo === 'multipla-escolha' || dataForm.tipo === 'unica-escolha') {
        let ordem = document.getElementsByClassName('ordem-option');
        let resposta = document.getElementsByClassName('resposta');
        let options = [];

        let validateIndice = 0;
        let validateOrdem = 0;

        for (let i = 0; i < resposta.length && i < ordem.length; i++) {
          if (!ordem[i].value || !resposta[i].value) {
            toastr.error('O item ' + i + ' está em branco');
            return false;
          }


          validateIndice += i;
          validateOrdem += Number(ordem[i].value);
          options.push(
              {ordem: ordem[i].value, message: resposta[i].value}
          );

        }
        if (validateIndice !== validateOrdem) {
          toastr.error('A ordem das respostas está enumerada incorretamente');
          return false;
        }

        dataForm.options = options;

      } else if (dataForm.tipo === 'texto' || dataForm.tipo === 'arquivo') {
        dataForm.tamanho = document.getElementById('tamanho').value
      }

      let id = this.$route.params.id;
      let produto_perguntasService = new produto_perguntaService();
      let response = await produto_perguntasService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {
    this.edit(this.$route.params.id);

  }
}
</script>

<style scoped>

</style>
