<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Leads</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormLeads></FormLeads>
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormLeads from "@/views/leads/FormLeads.vue";
import toastr from "toastr/build/toastr.min";
import LeadService from "@/services/lead.service";

export default {
  name: "CreateLeads",
  components: {FormLeads, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        cidade_id: document.getElementById('cidade_id').value,
        telefone: document.getElementById('telefone').value,
        atendimento: document.getElementById('atendimento').value,
        cpf: document.getElementById('cpf').value,
        email: document.getElementById('email').value,
        cnpj: document.getElementById('cnpj').value,
        aceita_comunicacao: document.getElementById('aceita_comunicacao').value,
        aceitou_termo: document.getElementById('aceitou_termo').value,
        tipo: document.getElementById('tipo').value,
        url: document.getElementById('url').value,
        status: document.getElementById('status').value,


      }
      let leadsService = new LeadService();
      let response = await leadsService.store(dataForm);

      if (response.data?.id) {
        location.href = '/leads/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
