<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Leads</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="../index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormLeads></FormLeads>
        <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormLeads from "@/views/leads/FormLeads.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import LeadService from "@/services/lead.service";

export default {
  name: "EditLeads",
  components: {LayoutPage, ButtonWidget, FormLeads},
  methods: {
    async edit(id) {
      let leadService = new LeadService();
      let response = await leadService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('cidade_id').value = response.data.cidade_id;
      document.getElementById('telefone').value = response.data.telefone;
      document.getElementById('atendimento').value = response.data.atendimento;
      document.getElementById('cpf').value = response.data.cpf;
      document.getElementById('email').value = response.data.email;
      document.getElementById('cnpj').value = response.data.cnpj;
      document.getElementById('aceita_comunicacao').value = response.data.aceita_comunicacao;
      document.getElementById('aceitou_termo').value = response.data.aceitou_termo;
      document.getElementById('tipo').value = response.data.tipo;
      document.getElementById('url').value = response.data.url;
      document.getElementById('status').value = response.data.status;

    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        cidade_id: document.getElementById('cidade_id').value,
        telefone: document.getElementById('telefone').value,
        atendimento: document.getElementById('atendimento').value,
        cpf: document.getElementById('cpf').value,
        email: document.getElementById('email').value,
        cnpj: document.getElementById('cnpj').value,
        aceita_comunicacao: document.getElementById('aceita_comunicacao').value,
        aceitou_termo: document.getElementById('aceitou_termo').value,
        tipo: document.getElementById('tipo').value,
        url: document.getElementById('url').value,
        status: document.getElementById('status').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let leadsService = new LeadService();
      let response = await leadsService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
