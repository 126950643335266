<template>
  <input-form class-list="col-12" type="text" label="Enunciado  " value="" name="enunciado"/>

  <input-form class-list="col-12" type="tinyint" label="Obrigatório " value="" name="obrigatorio"/>
  <input-form class-list="col-12" type="int" label="Ordem " value="" name="ordem"/>
  <input-form class-list="col-12" type="tinyint" label="Ativo " value="" name="ativo"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select"
              :items="[{id:'decimal',message:'decimal',}
              ,{id:'inteiro',message:'inteiro',},
              {id:'texto',message:'texto',},
              {id:'binario',message:'binario',},
              {id:'multipla-escolha',message:'multipla-escolha',},
              {id:'unica-escolha',message:'unica-escolha',},
              {id:'arquivo',message:'arquivo',},]"
              label="Tipo " value="" name="tipo"/>




</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormProdutoPerguntas",
  components: {InputForm},
  data() {
    return {
      tipo: null,
      indice: 0,
      rows: [
        {
          ordem: 0,
          message: ''
        }
      ]
    }
  },
  props:{
    itens:Object,
    tipoLoad:null
  },
  methods: {

  },
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
