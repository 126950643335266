<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Questionários</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'../index/'+this.$route.params.id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormProdutoQuestionarios></FormProdutoQuestionarios>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormProdutoQuestionarios from "@/views/produto_questionarios/FormProdutoQuestionarios.vue";
import produto_questionarioService from "@/services/produto_questionario.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateProdutoQuestionarios",
  components: {FormProdutoQuestionarios, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        ativo: document.getElementById('ativo').value,
        produto_id: this.$route.params.id,


      }
      let produto_questionariosService = new produto_questionarioService();
      let response = await produto_questionariosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/produto_questionarios/index/'+this.$route.params.id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
