<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="nome"/>
  <input-form placeholder="Selecione Cidade" url="/api/cities" class-list="col-12" type="select2"  label="Cidade "
              value="" name="cidade_id"/>
  <input-form class-list="col-12" type="string" label="Telefone " value="" name="telefone"/>
  <input-form class-list="col-12" type="select" label="Atendimento " :items="[{id:'telefone',message:'Telefone'},{id:'whatsapp',message:'Whatsapp'}]" value="" name="atendimento"/>
  <input-form class-list="col-12" type="string" label="CPF " value="" name="cpf"/>
  <input-form class-list="col-12" type="string" label="Email " value="" name="email"/>
  <input-form class-list="col-12" type="string" label="CNPJ " value="" name="cnpj"/>
  <input-form class-list="col-12" type="tinyint" label="Aceita Comunicacao " value="" name="aceita_comunicacao"/>
  <input-form class-list="col-12" type="tinyint" label="Aceitou Termo " value="" name="aceitou_termo"/>
  <input-form class-list="col-12" type="select" :items="[{id:'fisica',message:'Fisica'},{id:'juridica',message:'Jurídica'}]" label="Tipo " value="" name="tipo"/>
  <input-form class-list="col-12" type="string" label="Url " value="" name="url"/>
  <input-form class-list="col-12" type="select" label="Status " value="" :items="[{id:'novo',message:'Novo'},{id:'resolvido',message:'Resolvido'},{id:'atendendo',message:'Atendendo'}]" name="status"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import RequestHelper from "@/services/RequestHelper";


export default {
  name: "FormLeads",
  components: {InputForm},
  data() {
    return {cidades: null}
  },
  methods: {
    async getLeads() {
      let request = new RequestHelper();
      this.cidades = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/cities', {});


    },


  },
  created() {
    this.getLeads();
  }
}
</script>

<style scoped>
@import "../../../node_modules/toastr/build/toastr.css";
</style>
