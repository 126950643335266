<template>
<input-form class-list="col-12"  type="blob" label="" value="" name="arquivo"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormUserUnidadeAnexos",
    components: {InputForm},
    data() {
        return {}
    },
    methods:{},

created() {}
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
