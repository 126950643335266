<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Produtos</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormProdutos></FormProdutos>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormProdutos from "@/views/produtos/FormProdutos.vue";
import produtoService from "@/services/produto.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateProdutos",
  components: {FormProdutos, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        produto_categoria_id: document.getElementById('produto_categoria_id').value,
        ativo: document.getElementById('ativo').value,


      }
      let produtosService = new produtoService();
      let response = await produtosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/produtos/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>
