<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Questionários</h5>
            </div>
            <div class="w-50">
              <input id="search" v-model="search" class="form-control" placeholder="Digite sua pesquisa"
                     type="text" @change="list()">
            </div>

            <div class="float-end">
              <button-widget :href="'/produto_questionarios/create/'+this.$route.params.id" class="m-1"
                             cor="azul"
                             tamanho="M">
                Adicionar
              </button-widget>
              <button-widget class="m-1" cor="azul" href="/produtos/index" tamanho="M">
                Voltar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button aria-expanded="false" class="btn btn-system btn-sm dropdown-toggle"
                        data-bs-toggle="dropdown"
                        type="button">
                  Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="'/produto_questionarios/edit/'+row.id" class="dropdown-item">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                                    <span class="cursor-pointer dropdown-item p-2" @click="openModalQuestionario(row.id)">
                                      <i class="bi bi-link"></i>
                                      Gerar Link
                                    </span>
                  </li>
                  <li>
                                    <span class="cursor-pointer dropdown-item p-2" @click="irParaLink(row.id)">
                                      <i class="bi bi-link"></i>
                                      Ir  para Questionário
                                    </span>
                  </li>
                  <li>
                    <router-link :to="'/produto_perguntas/index/'+row.id" class="dropdown-item p-2">
                      <i class="bi bi-question-diamond-fill"></i>
                      Questões
                    </router-link>
                  </li>
                  <li>
                                        <span class="dropdown-item cursor-pointer p-2" @click="deleteRow(row.id)">
                                                                                <i class="bi bi-trash2-fill"></i>
                                            Apagar

                                     </span>
                  </li>
                </ul>
              </div>


            </td>

            <td>
              <div class="col-12"><strong>Nome : </strong>{{ row.nome }}</div>
              <div class="col-12"><strong>Ativo : </strong>{{ row.ativo ? 'sim' : 'não' }}</div>

            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td class="text-center" colspan="2"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>

    <modal-widget-vue v-if="modalQuestionario">
      <div class="row">
        <div class="col-6 pt-3">
          <h4>Link Questionário</h4>
        </div>
        <div class="col-6">
          <i @click="closeModalQuestionario()" class="bi bi-x float-end" style="font-size: 38px !important;"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="input-group">
            <input v-model="linkInput" class="form-control" type="text">
            <button :class="!buttonCopy?'btn btn-system':'btn btn-success'" @click="copy()">
              <i :class="!buttonCopy?'bi bi-clipboard-fill':'bi bi-clipboard-check-fill'"></i>
            </button>
          </div>

        </div>
      </div>
    </modal-widget-vue>
  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import produto_questionarioService from "@/services/produto_questionario.service";
import ModalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import UserService from "@/services/user.service";


export default {
  name: "IndexProdutoQuestionarios",
  components: {ModalWidgetVue, ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      modalQuestionario: false,
      buttonCopy: false,
      linkInput: ''

    }
  },
  methods: {
    copy() {
      this.buttonCopy = true;
      setTimeout(() => {
        this.buttonCopy = false;
      }, 2000);

      var tempInput = document.createElement("input");
      tempInput.value = this.linkInput;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);


    },
    closeModalQuestionario(){
      this.modalQuestionario = false;
    },
    async openModalQuestionario(id) {
      try {

         await this.gerarLink(id)
        this.modalQuestionario = await true;
      }catch (e) {
        console.log(e)
        toastr.error('Não foi possivel gerar o link');
      }

    },
    async list(id) {

      let produto_questionariosService = new produto_questionarioService();
      let dataRow = await produto_questionariosService.list(this.search, id);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let produto_questionariosService = new produto_questionarioService();
      let dataRow = await produto_questionariosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    async irParaLink(id){
      await this.gerarLink(id)
      window.open(this.linkInput);

    },
    async gerarLink(id){
      let user = await new UserService().me();
      let url = await location.origin + '/form/:hash';
      let payload = await {
        user_id: user.data.id,
            questionario_id: id

      };
      console.log(user)
      let token = await JSON.stringify(payload);
      this.linkInput = await url.replace(':hash', btoa(token))
    }



  },
  created() {
    this.list(this.$route.params.id);

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
