<script>
import InputForm from "@/components/form/inputForm.vue";

export default {
  name: "TextoTipo",
  components: {InputForm}
}
</script>

<template>
  <input-form class-list="col-12" type="int" label="Maximo de caracteres " value=""
              name="tamanho"/>
</template>

<style scoped>

</style>